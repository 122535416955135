import React, { useState } from 'react';

import './Feedback.css';

import Chat from '@mui/icons-material/Chat';

import FeedbackModal from './FeedbackModal/FeedbackModal';

import { useAlert } from '../../Context/AlertContext';

const Feedback: React.FC = () => {
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const { showAlert } = useAlert();

  return (
    <>
      <div 
        className="Feedback" 
        onClick={() => setOpenFeedbackModal(true)}
        title="Send Feedback"
      >
        <Chat className="feedback-icon" sx={{ fontSize: 22 }} />
      </div>
      
      {openFeedbackModal && (
        <FeedbackModal 
          setOpenFeedbackModal={setOpenFeedbackModal}
          showAlert={showAlert}
        />
      )}
    </>
  );
};

export default Feedback; 