import { getFunctions, httpsCallable } from 'firebase/functions';

// Initialize Firebase functions
const functions = getFunctions();

// Create a function to call the createCheckoutSession Cloud Function
export const createCheckoutSession = async (userId: string) => {
  const isTestMode = process.env.REACT_APP_STRIPE_TEST_MODE === 'true';
  const functionToUse = isTestMode ? 'createTestCheckoutSession' : 'createCheckoutSession';

  try {
    const createCheckoutSessionFn = httpsCallable(functions, functionToUse);
    const result = await createCheckoutSessionFn({ userId });
    return result.data as { id: string; url: string };
  } catch (error) {
    console.error('Error creating checkout session:', error);
    throw error;
  }
};

// Create a function to call the createCustomerPortalSession Cloud Function
export const createCustomerPortalSession = async () => {
  const isTestMode = process.env.REACT_APP_STRIPE_TEST_MODE === 'true';
  const functionToUse = isTestMode ? 'createTestCustomerPortalSession' : 'createCustomerPortalSession';

  try {
    const createPortalSessionFn = httpsCallable(functions, functionToUse);
    const result = await createPortalSessionFn();
    return result.data as { url: string };
  } catch (error) {
    console.error('Error creating customer portal session:', error);
    throw error;
  }
}; 