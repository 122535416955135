import React, { useState, useEffect, useCallback } from 'react'

import "./Playing.css"

import { useGame } from '../../../../Context/GameContext'
import { useAuth } from '../../../../Context/AuthContext'

import { Quiz } from '../../../../types/Quiz'
import { GameStateType } from '../../../../types/GameStateType'
import { GameModeType } from '../../../../types/GameModeType'
import { DailyChallenge } from '../../../../types/DailyChallengeType'

import GameImages from './GameImages/GameImages'
import GameChoices from './GameChoices/GameChoices'
import GameEnded from './GameEnded/GameEnded'
import GameControlBar from './GameControlBar/GameControlBar'
import GameAdminBar from './GameAdminBar/GameAdminBar'

import { fetchQuizzesFirestore, deleteQuizFirestoreDocument } from '../../../../services/firestoreService'
import { fetchDailyChallengeQuizzes, getDailyChallengeDocument, recordDailyChallengePlay, checkUserDailyChallengeStatus } from '../../../../services/dailyChallengeService'

interface PlayingProps {
  showShareModal: boolean;
  setShowShareModal: (show: boolean) => void;
  setCorrectAnswer: (answer: string) => void;
}

const Playing: React.FC<PlayingProps> = ({ showShareModal, setShowShareModal, setCorrectAnswer }) => {
  const { gameState, setGameState, gameMode, gameDifficulty, customCountries } = useGame();
  const { user } = useAuth()

  const [quizzes, setQuizzes] = useState<Quiz[]>([]);
  const [currentQuiz, setCurrentQuiz] = useState<Quiz | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fetchQuizState, setFetchQuizState] = useState("good");

  // Daily Challenge
  const [dailyQuizzesFetched, setDailyQuizzesFetched] = useState(false);
  const [playRecorded, setPlayRecorded] = useState(false);
  const [dailyChallengeAlreadyPlayed, setDailyChallengeAlreadyPlayed] = useState(false);
  const [dailyChallengeDocument, setDailyChallengeDocument] = useState<DailyChallenge | null>(null);

  useEffect(() => {
    if (gameMode === GameModeType.DailyChallenge && user?.uid) {
      const checkDailyChallengeStatus = async () => {
        try {
          const result = await checkUserDailyChallengeStatus(user.uid);
          if (result && result.played) {
            setDailyChallengeAlreadyPlayed(true);
            if (gameState === GameStateType.Playing) {
              setGameState(GameStateType.End);
            }
          }
        } catch (error) {
          console.error('Error checking daily challenge status:', error);
        }
      };
      
      checkDailyChallengeStatus();
    }
  }, [gameMode, user?.uid, gameState, setGameState]);

  const fetchQuizzes = useCallback(async () => {
    let fetchedQuizzes: Quiz[] = [];
    
    if (gameMode === GameModeType.DailyChallenge) {
      if (!dailyQuizzesFetched) {
        try {
          const dailyChallenge = await getDailyChallengeDocument();
          setDailyChallengeDocument(dailyChallenge);
          const dailyQuizzes = await fetchDailyChallengeQuizzes();
          fetchedQuizzes = dailyQuizzes;
          
          setDailyQuizzesFetched(true);
        } catch (error) {
          console.error('Error fetching daily challenge quizzes:', error);
          setFetchQuizState("fail");
          return;
        }
      } else {
        return null;
      }
    } else {
      fetchedQuizzes = await fetchQuizzesFirestore(gameMode, customCountries);
    }
    
    if (fetchedQuizzes.length > 0) {
      setQuizzes(fetchedQuizzes);
      setCurrentQuiz(fetchedQuizzes[0]);
      setCurrentIndex(0);
    } else {
      setFetchQuizState("fail")
      console.log("No quizzes found, try again later.");
    }
  }, [gameMode, customCountries, dailyQuizzesFetched]);

  useEffect(() => {
    const recordPlay = async () => {
      if (gameMode === GameModeType.DailyChallenge && dailyQuizzesFetched && !playRecorded && !dailyChallengeAlreadyPlayed) {
        try {
          await recordDailyChallengePlay();
          setPlayRecorded(true);
        } catch (error) {
          console.error('Error recording play:', error);
        }
      }
    };
    
    recordPlay();
  }, [gameMode, dailyQuizzesFetched, playRecorded, dailyChallengeAlreadyPlayed]);

  const nextQuiz = useCallback(() => {
    if (currentIndex < quizzes.length - 1) {
      setCurrentIndex(prevIndex => prevIndex + 1);
      setCurrentQuiz(quizzes[currentIndex + 1]);
    } else if (gameMode === GameModeType.DailyChallenge) {
      setGameState(GameStateType.End);
    } else {
      console.log("No more quizzes, fetching new quizzes.");
      fetchQuizzes();
    }
  }, [currentIndex, quizzes, fetchQuizzes, gameMode, setGameState]);

  const prevQuiz = useCallback(() => {
    if (currentIndex > 0) {
      setCurrentIndex(prevIndex => prevIndex - 1);
      setCurrentQuiz(quizzes[currentIndex - 1]);
    } else {
      console.log("This is the first quiz.");
    }
  }, [currentIndex, quizzes]);

  const deleteQuiz = useCallback(async () => {
    if (currentQuiz?.id && currentQuiz?.region) {
      try {
        await deleteQuizFirestoreDocument(currentQuiz.id, currentQuiz.country);
        setQuizzes(prevQuizzes => prevQuizzes.filter(quiz => quiz.id !== currentQuiz.id));
        nextQuiz();
      } catch (error) {
        console.error("Error deleting quiz:", error);
      }
    } else {
      console.error("Cannot delete quiz: missing id or region");
    }
  }, [currentQuiz, nextQuiz]);

  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key === 'ArrowRight') {
      nextQuiz();
    } else if (event.key === 'ArrowLeft') {
      prevQuiz();
    }
  }, [nextQuiz, prevQuiz]);

  useEffect(() => {
    if (user?.role === "admin") {
      window.addEventListener('keydown', handleKeyPress);
      return () => {
        window.removeEventListener('keydown', handleKeyPress);
      };
    }
  }, [handleKeyPress, user?.role]);

  useEffect(() => {
    fetchQuizzes();
  }, [fetchQuizzes]);

  const handlePlayAgain = () => {
    if (gameMode === GameModeType.DailyChallenge) {
      setGameState(GameStateType.DailyChallenge);
    } else {
      setGameState(GameStateType.Playing);
    }
  };

  const handleShare = () => {
    setShowShareModal(true);
    setCorrectAnswer(currentQuiz?.country || "");
  }

  return (
    <div className='Playing'>
      {currentQuiz && (fetchQuizState === "good") ? (
        <>
          <GameImages imageUrl={currentQuiz.imageUrl} />

          <GameControlBar nextQuiz={nextQuiz} currentQuiz={currentQuiz} />

          {gameState === GameStateType.Playing && (
            <GameChoices 
              gameMode={gameMode}
              correctAnswer={currentQuiz.country}
              quizId={currentQuiz.id}
              nextQuiz={nextQuiz}
              setGameState={setGameState}
              gameDifficulty={gameDifficulty}
              dailyChallengeMode={dailyChallengeDocument?.gameMode}
            />
          )}

          {gameState === GameStateType.End && (
            <GameEnded handlePlayAgain={handlePlayAgain} handleShare={handleShare} correctAnswer={currentQuiz.country} gameMode={gameMode} />
          )}

          {user?.role === "admin" && 
            <GameAdminBar 
              nextQuiz={nextQuiz}
              prevQuiz={prevQuiz}
              deleteQuiz={deleteQuiz}
            />
          }
        </>
      ) : (
        <p>Loading quizzes, wait or refresh...</p>
      )}
    </div>
  );
}

export default Playing