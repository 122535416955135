import React, { useState, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import './App.css';

import Navbar from './components/Navbar/Navbar';
import Body from './components/Body/Body';
import Footer from './components/Footer/Footer';

import LoginModal from './components/LoginModal/LoginModal';
import ProfileModal from './components/ProfileModal/ProfileModal';
import PremiumModal from './components/PremiumModal/PremiumModal';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import SubscriptionSuccess from './pages/SubscriptionSuccess';
import SubscriptionCancel from './pages/SubscriptionCancel';

import { PointsProvider } from './Context/PointsContext';
import { GameProvider } from './Context/GameContext';
import { AuthProvider, useAuth } from './Context/AuthContext';
import { AnalyticsProvider } from './Context/AnalyticsContext';
import { AlertProvider, useAlert } from './Context/AlertContext';
import { PremiumProvider, usePremium } from './Context/PremiumContext';

const AppContent: React.FC = () => {
  const [showLoginmodal, setShowLoginModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const { isPremiumModalOpen, hidePremiumModal } = usePremium();
  const { user } = useAuth();
  const { showAlert } = useAlert();
  
  const setLoginModal = useCallback((state: boolean) => {
    setShowLoginModal(state);
  }, []);

  const setProfileModal = useCallback((state: boolean) => {
    setShowProfileModal(state);
  }, []);

  return (
    <div className="App">
      <Helmet>
        <title>Nationality Guesser | Fun Quiz Game to Test Your Geography Knowledge</title>
        <meta name="description" content="Challenge yourself with Nationality Guesser, a fun and educational quiz game where you guess people's nationalities from around the world." />
        <link rel="canonical" href="https://nationalityguesser.com/" />
      </Helmet>
      
      <Navbar 
        setOpenLoginModal={setLoginModal} 
        setOpenProfileModal={setProfileModal} 
      />
      
      <Routes>
        <Route path="/" element={<Body setOpenLoginModal={setLoginModal} />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsofservice" element={<TermsOfService />} />
        <Route path="/subscription/success" element={<SubscriptionSuccess />} />
        <Route path="/subscription/cancel" element={<SubscriptionCancel />} />
      </Routes>
      
      <Footer />
      {showLoginmodal && (
        <LoginModal setOpenLoginModal={setLoginModal} />
      )}
      {showProfileModal && (
        <ProfileModal setOpenProfileModal={setProfileModal} />
      )}
      {isPremiumModalOpen && (
        <PremiumModal setOpenPremiumModal={hidePremiumModal} showAlert={showAlert} user={user} />
      )}
    </div>
  );
};

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <AnalyticsProvider>
        <AlertProvider> 
          <GameProvider>
            <AuthProvider>
              <PointsProvider>
                <PremiumProvider>
                  <Router>
                    <AppContent />
                  </Router>
                </PremiumProvider>
              </PointsProvider>
            </AuthProvider> 
          </GameProvider>
        </AlertProvider>
      </AnalyticsProvider>
    </HelmetProvider>
  );
};

export default App;