import React from 'react';
import { DailyChallengeAnswer } from '../../../../../types/DailyChallengeType';

import './DailyChallengeAnswers.css';

interface DailyChallengeAnswersProps {
  answers: DailyChallengeAnswer[];
}

const DailyChallengeAnswers: React.FC<DailyChallengeAnswersProps> = ({ answers }) => {
  return (
    <div className="DailyChallengeAnswers">
      {answers.map((answer, index) => (
        <div 
          key={index} 
          className={`answer-square ${answer.correct ? 'correct' : answer.skipped ? 'skipped' : 'incorrect'}`}
          title={answer.correct ? 'Correct' : answer.skipped ? 'Skipped' : 'Incorrect'}
        />
      ))}
    </div>
  );
};

export default DailyChallengeAnswers; 