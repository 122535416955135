import React from 'react'

import './BasicButton.css'

interface BasicButtonProps {
  text: string;
  icon?: React.ReactElement;
  onClick: () => void;
  btnClass? : string;
  disabled?: boolean;
}

const BasicButton: React.FC<BasicButtonProps> = ({ text, icon, onClick, btnClass, disabled }) => {
  const getButtonClass = (text: string) => {
    switch (text) {
      case "Logout":
        return "LogoutButton";
      case "Sign-in":
        return "LoginButton";
      case "Submit":
        return "SubmitButton";
      case "Submit2":
        return "SubmitButton2";
      default:
        return "BasicButton";
    }
  };

  const buttonClass = btnClass ? btnClass : getButtonClass(text);

  return (
    <button className={buttonClass} onClick={onClick} disabled={disabled}>
      {icon}
      {text}
    </button>
  );
};

export default BasicButton;
