import { query, where, orderBy, limit, getDocs, increment, doc, updateDoc, getDoc, setDoc, serverTimestamp, collectionGroup } from 'firebase/firestore';

import { DAILY_CHALLENGES_DATABASE, QUIZZES_DATABASE, USERS_DATABASE, USER_DAILY_CHALLENGES_SUBCOLLECTION } from './firestoreService';

import { DailyChallenge, DailyChallengeAnswer, UserDailyChallengeResult } from '../types/DailyChallengeType';
import { Quiz } from '../types/Quiz';
import { User } from '../types/UserType';
import { db } from '../firebase/firebase-config';

const formatDateCET = (date: Date): string => {
  const cetOffset = 60; // CET is UTC+1
  const localOffset = date.getTimezoneOffset();
  const cetDate = new Date(date.getTime() + (localOffset + cetOffset) * 60000);
  return cetDate.toISOString().split('T')[0]; // YYYY-MM-DD format
};

export const getDailyChallengeDocument = async (): Promise<DailyChallenge> => {
  try {
    const today = formatDateCET(new Date());
    
    // First try to get today's challenge
    const dailyChallengesRef = DAILY_CHALLENGES_DATABASE;
    let q = query(
      dailyChallengesRef,
      where('challengeDate', '==', today),
      limit(1)
    );
    
    let querySnapshot = await getDocs(q);
    
    // If no challenge today, get the most recent one
    if (querySnapshot.empty) {
      q = query(
        dailyChallengesRef,
        orderBy('challengeDate', 'desc'),
        limit(1)
      );
      
      querySnapshot = await getDocs(q);
    }
    
    if (!querySnapshot.empty) {
      const dailyChallenge = querySnapshot.docs[0].data() as DailyChallenge;
      return dailyChallenge;
    }
    
    throw new Error('No daily challenge found');
  } catch (error) {
    console.error('Error getting daily challenge:', error);
    throw error;
  }
};

export const fetchDailyChallengeQuizzes = async (): Promise<Quiz[]> => {
  try {
    const dailyChallengeDocument = await getDailyChallengeDocument();

    const quizIds = dailyChallengeDocument.quizIds || [];
    const quizzes: Quiz[] = [];
    
    for (const quizId of quizIds) {
      const quizDocRef = doc(QUIZZES_DATABASE, quizId);
      const quizDoc = await getDoc(quizDocRef);
      
      if (quizDoc.exists()) {
        quizzes.push({
          id: quizDoc.id,
          ...quizDoc.data()
        } as Quiz);
      }
    }
    
    return quizzes;
  } catch (error) {
    console.error('Error fetching daily challenge quizzes:', error);
    return [];
  }
};

// To record a play for the daily challenge
export const recordDailyChallengePlay = async (): Promise<void> => {
  try {
    const dailyChallengeDocument = await getDailyChallengeDocument();
    await incrementDailyChallengePlayCount(dailyChallengeDocument.id);
  } catch (error) {
    console.error('Error recording daily challenge play:', error);
  }
};

// To increment the daily challenge play count
export const incrementDailyChallengePlayCount = async (challengeId: string) => {
  const challengeRef = doc(DAILY_CHALLENGES_DATABASE, challengeId);

  await updateDoc(challengeRef, {
    playCount: increment(1)
  });
};

export const checkUserDailyChallengeStatus = async (userId: string) => {
  try {
    const dailyChallengeDoc = await getDailyChallengeDocument();
    
    if (!dailyChallengeDoc || !dailyChallengeDoc.id) {
      return { played: false };
    }
    
    const userDailyChallengeRef = doc(
      USERS_DATABASE, 
      userId, 
      USER_DAILY_CHALLENGES_SUBCOLLECTION,
      dailyChallengeDoc.id
    );
    
    const userDailyChallengeDoc = await getDoc(userDailyChallengeRef);
    
    if (userDailyChallengeDoc.exists()) {
      return {
        played: true,
        results: userDailyChallengeDoc.data(),
      };
    }
    
    return { played: false };
  } catch (error) {
    console.error('Error checking user daily challenge status:', error);
    return { played: false };
  }
};

// Save user's daily challenge results to Firestore
export const saveDailyChallengeResults = async (
  userId: string,
  answers: DailyChallengeAnswer[]
) => {
  try {
    const dailyChallengeDoc = await getDailyChallengeDocument();
    
    if (!dailyChallengeDoc || !dailyChallengeDoc.id) {
      throw new Error('No daily challenge found');
    }
    
    const score = answers.filter(a => a.correct).length;
    const today = formatDateCET(new Date());
    
    const userDailyChallengeRef = doc(
      USERS_DATABASE, 
      userId, 
      USER_DAILY_CHALLENGES_SUBCOLLECTION, 
      dailyChallengeDoc.id
    );
    
    const result: UserDailyChallengeResult = {
      challengeId: dailyChallengeDoc.id,
      challengeDate: today,
      completed: true,
      answers: answers,
      score: score,
      totalQuestions: dailyChallengeDoc.quizIds.length,
      playedAt: serverTimestamp(),
    };
    
    await setDoc(userDailyChallengeRef, result);
  } catch (error) {
    console.error('Error saving daily challenge results:', error);
  }
};

// Fetch top 10 users who completed today's daily challenge, ordered by score
export const fetchDailyChallengeLeaderboard = async (): Promise<{
  id: string;
  username: string;
  score: number;
  answers: DailyChallengeAnswer[];
  subscription?: "Standard" | "Premium";
}[]> => {
  try {
    const dailyChallengeDoc = await getDailyChallengeDocument();
    if (!dailyChallengeDoc || !dailyChallengeDoc.id) {
      throw new Error('No daily challenge found');
    }

    // Query across all subcollections named "dailyChallenges"
    const leaderboardQuery = query(
      collectionGroup(db, USER_DAILY_CHALLENGES_SUBCOLLECTION),
      where('challengeId', '==', dailyChallengeDoc.id),
      where('completed', '==', true),
      orderBy('score', 'desc'),
      limit(10)
    );

    const querySnapshot = await getDocs(leaderboardQuery);
    const leaderboardResults: {
      id: string;
      username: string;
      score: number;
      answers: DailyChallengeAnswer[];
      subscription?: "Standard" | "Premium";
    }[] = [];

    // For each daily challenge result, get the userID.
    // Assuming document paths look like: "users/{userId}/dailyChallenges/{challengeDocId}".
    for (const docSnap of querySnapshot.docs) {
      const resultData = docSnap.data() as UserDailyChallengeResult;
      
      // Extract the user ID from the parent document reference.
      const userId = docSnap.ref.parent.parent?.id;
      if (!userId) continue;

      // Fetch the user document to get the username.
      const userDocRef = doc(USERS_DATABASE, userId);
      const userDocSnap = await getDoc(userDocRef);
      const userData = userDocSnap.exists() ? userDocSnap.data() as User : null;
      const username = userData?.username || (resultData as any).username || userId;
      const subscription = userData?.subscription || "Standard";

      leaderboardResults.push({
        id: userId,
        username,
        score: resultData.score,
        answers: resultData.answers,
        subscription
      });
    }

    return leaderboardResults;
  } catch (error) {
    console.error('Error fetching daily challenge leaderboard:', error);
    return [];
  }
};