import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';

import './SubscriptionCancel.css';

const SubscriptionSuccess: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [verifying, setVerifying] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');
    
    if (sessionId) {
      console.log('Payment successful with session ID:', sessionId);
      setVerifying(true);
      
      setLoading(false);
      setVerifying(false);
      
      const timer = setTimeout(() => {
        navigate('/');
      }, 5000);
      
      return () => clearTimeout(timer);
    } else {
      console.warn('No session ID provided in URL');
      navigate('/');
    }
  }, [location.search, navigate, user]);
  
  return (
    <div className="subscription-container">
      <h1>Subscription Successful!</h1>
      <p>Thank you for subscribing to our premium plan!</p>
      {loading ? (
        <p>Processing your subscription...</p>
      ) : verifying ? (
        <p>Verifying your payment...</p>
      ) : (
        <p>Your premium features are now available! You will be redirected to the home page in a few seconds...</p>
      )}
    </div>
  );
};

export default SubscriptionSuccess; 