import React from 'react';
import './PremiumElement.css';
import StarIcon from '@mui/icons-material/Star';

interface PremiumElementProps {
  onClick: () => void;
}

const PremiumElement: React.FC<PremiumElementProps> = ({ onClick }) => {
  return (
    <button className="PremiumElement" onClick={onClick} >
      <StarIcon sx={{ fontSize: 20 }} />
      <p>Premium</p>
    </button>
  );
};

export default PremiumElement; 