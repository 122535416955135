import React from 'react';
import { Helmet } from 'react-helmet-async';
import './Pages.css';

const TermsOfService: React.FC = () => {
  return (
    <div className="legal-page">
      <Helmet>
        <title>Terms of Service | Nationality Guesser</title>
        <meta name="description" content="Terms of Service for Nationality Guesser web app. Read our rules and guidelines for using our nationality guessing quiz game." />
        <link rel="canonical" href="https://nationalityguesser.com/termsofservice" />
      </Helmet>
      <div className="legal-container">
        <h1>Terms of Service</h1>
        <p>Last updated: 17/03/2025</p>
        
        <section>
          <h2>1. Introduction</h2>
          <p>Welcome to Nationality Guesser. These Terms of Service govern your use of our web application and services. By accessing or using our application, you agree to be bound by these Terms. If you disagree with any part of the terms, you may not access the service.</p>
        </section>
        
        <section>
          <h2>2. Accounts</h2>
          <p>When you create an account with us, you must provide information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our service.</p>
          <p>You are responsible for safeguarding the password that you use to access the service and for any activities or actions under your password.</p>
          <p>You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
        </section>
        
        <section>
          <h2>3. Intellectual Property</h2>
          <p>The service and its original content, features, and functionality are and will remain the exclusive property of Nationality Guesser and its licensors. The service is protected by copyright, trademark, and other laws of both the United States and foreign countries.</p>
          <p>Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Nationality Guesser.</p>
        </section>
        
        <section>
          <h2>4. User Content</h2>
          <p>Our service may allow you to post, link, store, share and otherwise make available certain information, text, graphics, or other material. You are responsible for the content that you post to the service, including its legality, reliability, and appropriateness.</p>
          <p>By posting content to the service, you grant us the right to use, modify, publicly perform, publicly display, reproduce, and distribute such content on and through the service. You retain any and all of your rights to any content you submit, post or display on or through the service and you are responsible for protecting those rights.</p>
        </section>
        
        <section>
          <h2>5. Prohibited Uses</h2>
          <p>You may use our service only for lawful purposes and in accordance with these Terms. You agree not to use the service:</p>
          <ul>
            <li>In any way that violates any applicable national or international law or regulation.</li>
            <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way.</li>
            <li>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter," "spam," or any other similar solicitation.</li>
            <li>To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity.</li>
            <li>In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful.</li>
          </ul>
        </section>
        
        <section>
          <h2>6. Termination</h2>
          <p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
          <p>Upon termination, your right to use the service will immediately cease. If you wish to terminate your account, you may simply discontinue using the service or contact us to request account deletion.</p>
        </section>
        
        <section>
          <h2>7. Limitation of Liability</h2>
          <p>In no event shall Nationality Guesser, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:</p>
          <ul>
            <li>Your access to or use of or inability to access or use the service;</li>
            <li>Any conduct or content of any third party on the service;</li>
            <li>Any content obtained from the service; and</li>
            <li>Unauthorized access, use or alteration of your transmissions or content.</li>
          </ul>
        </section>
        
        <section>
          <h2>8. Disclaimer</h2>
          <p>Your use of the service is at your sole risk. The service is provided on an "AS IS" and "AS AVAILABLE" basis. The service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</p>
        </section>
        
        <section>
          <h2>9. Governing Law</h2>
          <p>These Terms shall be governed and construed in accordance with the laws, without regard to its conflict of law provisions.</p>
          <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect.</p>
        </section>
        
        <section>
          <h2>10. Changes to Terms</h2>
          <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
          <p>By continuing to access or use our service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the service.</p>
        </section>
        
        <section>
          <h2>11. Contact Us</h2>
          <p>If you have any questions about these Terms, please contact us at:</p>
          <p>Email: contact.help.ayr@gmail.com</p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService; 