import React from 'react';
import './SubscribeToPremiumButton.css';
import DiamondIcon from '@mui/icons-material/Diamond';

interface SubscribeToPremiumButtonProps {
  onClick: () => void;
  isGuest: boolean;
}

const SubscribeToPremiumButton: React.FC<SubscribeToPremiumButtonProps> = ({ onClick, isGuest }) => {
  const isMobile = window.innerWidth < 550;
  return (
    <button 
      className="SubscribeToPremiumButton" 
      onClick={onClick}
    >
      <DiamondIcon sx={{ fontSize: 20 }} />
      {!(isGuest && isMobile) && <p>Get Premium</p>}
    </button>
  );
};

export default SubscribeToPremiumButton; 