import React, { useEffect, useState } from 'react';
import './DailyChallenge.css';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import GroupsIcon from '@mui/icons-material/Groups';
import { flagMap } from '../../../../config/flagData';

import { usePoints } from '../../../../Context/PointsContext';

interface DailyChallengeProps {
  onClick: () => void;
  isSelected: boolean;
}

const DailyChallenge: React.FC<DailyChallengeProps> = ({ onClick, isSelected }) => {
  const [remainingTime, setRemainingTime] = useState<string>('');

  const { dailyChallengeGameMode, dailyChallengePlayCount } = usePoints();
  
  useEffect(() => {
    const updateRemainingTime = () => {
      const now = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      
      const difference = tomorrow.getTime() - now.getTime();
      const hours = Math.floor(difference / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      
      setRemainingTime(`${hours}h ${minutes}m`);
    };
    
    updateRemainingTime();
    const timer = setInterval(updateRemainingTime, 60000);
    
    return () => clearInterval(timer);
  }, []);

  return (
    <button 
      className={`daily-challenge ${isSelected ? 'daily-challenge-selected' : ''}`} 
      onClick={onClick}
      disabled={!dailyChallengeGameMode}
    >
      <div className="daily-challenge-header">
        <div className="daily-challenge-title">
          <h3>Daily Challenge</h3>
        </div>
        <div className="daily-refresh">
          <AutorenewIcon className="refresh-icon" sx={{ fontSize: 16 }} />
          <span>{remainingTime}</span>
        </div>
      </div>
      
      <div className="daily-challenge-content">
        <div className="daily-challenge-region">
          <p>Players:</p>
          <div className={`daily-challenge-flag-container ${isSelected ? 'daily-challenge-flag-container-selected' : ''}`}>
            <p className='daily-challenge-region-bold'>{dailyChallengePlayCount * 3}</p>
            <GroupsIcon sx={{ fontSize: 18 }} />
          </div>
        </div>

        <div className="daily-challenge-region">
          <p>Today's Region:</p>
          <div className="daily-challenge-flag-container">
            <p className='daily-challenge-region-bold'>{dailyChallengeGameMode}</p>
            {flagMap[dailyChallengeGameMode] !== undefined && (
              <img 
                src={flagMap[dailyChallengeGameMode]} 
                alt={dailyChallengeGameMode} 
                className="daily-challenge-flag" 
              />
            )}
          </div>
        </div>
      </div>
    </button>
  );
};

export default DailyChallenge; 