import React, { useState, useEffect } from 'react'

import './ProfileElement.css'

import LoginIcon from '@mui/icons-material/Login';
import FaceIcon from '@mui/icons-material/Face';

import BasicButton from '../../BasicButton/BasicButton'

import { useAuth } from '../../../Context/AuthContext';

interface ProfileElementProps {
  iconSize: number,
  setOpenLoginModal: (open: boolean) => void;
  setOpenProfileModal: (open: boolean) => void;
  removeLoginText: boolean;
}

const ProfileElement: React.FC<ProfileElementProps> = ({ iconSize, setOpenLoginModal, setOpenProfileModal, removeLoginText }) => {
  const { user } = useAuth();

  const [username, setUsername] = useState<string | null>(null);

  const isAuthenticatedUser = user?.userType === "authenticated";

  useEffect(() => {
    if (user) {
      setUsername(user.username);
    }
  }, [user]);

  return (
    <div className="ProfileElement">
      <div className="profile-section">

        <button 
          className="profile-button" 
          onClick={isAuthenticatedUser ? () => setOpenProfileModal(true) : () => setOpenLoginModal(true)}
        >
          <FaceIcon sx={{ fontSize: 20 }} />
          <p>{username}</p>
        </button>

        {!isAuthenticatedUser && (
          <BasicButton btnClass='LoginButton' text={removeLoginText ? "" : "Sign-in" }icon={<LoginIcon sx={{ fontSize: iconSize }} />} onClick={() => setOpenLoginModal(true)} />
        )}
      </div>
    </div>
  )
}

export default ProfileElement