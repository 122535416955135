import React, { useEffect, useState } from 'react'

import "./Gamemode.css"

import { quizOptionsObject } from '../../../../config/quizOptionsObject'
import { superRegions } from '../../../../config/superRegions'

import { GameModeType } from '../../../../types/GameModeType'

import ProgressBarMini from '../../../ProgressBar/ProgressBarMini'
import CountriesContainer from '../../../CountriesContainer/CountriesContainer'

import { useAuth } from '../../../../Context/AuthContext'

import { fetchGamemodeStats } from '../../../../services/firestoreService'

import { useGame } from '../../../../Context/GameContext'

import { levelImageMap } from '../../../../config/levelImageData'

const levels = [0, 50, 150, 300, 500, 1000, 1750, 2500, 3500, 5000];
const levelNames = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

interface GamemodeProps {
  gameMode: GameModeType,
  className: string,
  onClick: () => void;
  disabled: boolean;
}

const Gamemode: React.FC<GamemodeProps> = ({ gameMode, className, onClick, disabled }) => {
  const { user } = useAuth()
  const { gameState } = useGame()
  const [points, setPoints] = useState<number>(0)

  const formattedGameMode = gameMode.replace(/\s+/g, '');

  let displayCountries: string[] = [];
  let remainingCount = 0
  let isSuperRegion = false

  if (Object.keys(superRegions).includes(formattedGameMode)) {
    remainingCount = 0
    isSuperRegion = true
  } else {
    const countries = quizOptionsObject[formattedGameMode] || [];
    displayCountries = countries.slice(0, 4);
    remainingCount = countries.length > 4 ? countries.length - 4 : 0;
  }

  const getLevelInfo = (points: number) => {
    for (let i = levels.length - 1; i >= 0; i--) {
      if (points >= levels[i]) {
        return {
          currentLevelName: levelNames[i],
          previousLevelPoints: levels[i],
          nextLevelPoints: levels[i + 1] || levels[i],
        };
      }
    }
    return {
      currentLevelName: levelNames[0],
      previousLevelPoints: levels[0],
      nextLevelPoints: levels[1],
    };
  };

  const levelInfo = getLevelInfo(points);

  useEffect(() => {
    const fetchStats = async () => {
      if (user?.uid) {
        const result = await fetchGamemodeStats(user.uid, gameMode);
        setPoints(result.points);
      }
    };

    fetchStats();
  }, [gameState, gameMode, user]);

  return (
    <button className={className} onClick={onClick} disabled={disabled}>
      <div className="gamemodeContainer">
        <div className="levelRegionContainer">
          <img className="level" src={levelImageMap[levelInfo.currentLevelName]} alt='level'/>    
          <p>{gameMode}</p>
        </div>

        <CountriesContainer 
          isSuperRegion={isSuperRegion}
          gameMode={gameMode}
          displayCountries={displayCountries}
          remainingCount={remainingCount}
        />
      </div>

      <ProgressBarMini points={points} levelInfo={levelInfo}/>
    </button>
  )
}

export default Gamemode