import React from 'react';
import './CountriesContainer.css';
import { flagMap } from '../../config/flagData';

interface CountriesContainerProps {
  isSuperRegion: boolean;
  gameMode: string;
  displayCountries: string[];
  remainingCount: number;
}

const CountriesContainer: React.FC<CountriesContainerProps> = ({
  isSuperRegion,
  gameMode,
  displayCountries,
  remainingCount
}) => {
  return (
    <div className="countriesContainer">
      {isSuperRegion ? (
        <img 
          src={flagMap[gameMode]} 
          alt={gameMode} 
          className='gamemodeSuperRegion' 
        />
      ): (
        displayCountries.map((country, index) => (
          <img 
            key={index}
            src={flagMap[country]} 
            alt={country} 
            className='gamemodeCountry' 
          />
        ))
      )}

      {remainingCount > 0 && (
        <div className="gamemodeCountry moreCountries">
          <p>{remainingCount}+</p>
        </div>
      )}
    </div>
  );
};

export default CountriesContainer; 