import React, { useState, useEffect, useCallback } from 'react'

import "./Leaderboard.css"

import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import GroupsIcon from '@mui/icons-material/Groups';

import { LeaderboardMemberType } from '../../../types/LeaderboardMemberType';
import { GameModeType } from '../../../types/GameModeType';
import { GameStateType } from '../../../types/GameStateType';
import { DailyChallengeAnswer } from '../../../types/DailyChallengeType';

import LeaderboardMember from './LeaderboardMember/LeaderboardMember';

import { fetchTopUsersByGamemode } from '../../../services/firestoreService';
import { fetchDailyChallengeLeaderboard } from '../../../services/dailyChallengeService';

import { useGame } from '../../../Context/GameContext';
import { usePoints } from '../../../Context/PointsContext';

const Leaderboard: React.FC = () => {
  const { gameMode, gameState } = useGame()
  const { dailyChallengeGameMode, dailyChallengePlayCount } = usePoints();

  const [leaderboardMembers, setLeaderboardMembers] = useState<LeaderboardMemberType[]>([]);
  const [dailyChallengeMembers, setDailyChallengeMembers] = useState<{
    id: string;
    username: string;
    score: number;
    answers: DailyChallengeAnswer[];
  }[]>([]);
  const [leaderboardType, setLeaderboardType] = useState<"Least" | "Most" | "Streak">("Least");
  const [loading, setLoading] = useState<boolean>(true);

  const isDailyChallenge = gameMode === GameModeType.DailyChallenge || gameState === GameStateType.DailyChallenge;

  const fetchDailyChallengeData = useCallback(async () => {
    setLoading(true);
    try {
      const members = await fetchDailyChallengeLeaderboard();
      setDailyChallengeMembers(members);
    } catch (error) {
      console.error("Error fetching daily challenge leaderboard data:", error);
      setDailyChallengeMembers([]);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleLeaderboardChange = useCallback(async (leaderboard: "Least" | "Most" | "Streak") => {
    setLoading(true);

    try {
      let members: LeaderboardMemberType[];

      switch (leaderboard) {
        case "Least":
          members = await fetchTopUsersByGamemode(gameMode, "points");
          break;
        case "Most":
          members = await fetchTopUsersByGamemode(gameMode, "points");
          break;
        case "Streak":
          members = await fetchTopUsersByGamemode(gameMode, "highscoreStreak");
          break;
        default:
          members = [];
      }
      
      setLeaderboardMembers(members);
      setLeaderboardType(leaderboard);
    } catch (error) {
      console.error("Error fetching leaderboard data:", error);
      setLeaderboardMembers([]);
    } finally {
      setLoading(false);
    }
  }, [gameMode]);

  useEffect(() => {
    const fetchData = async () => {
      if (isDailyChallenge) {
        await fetchDailyChallengeData();
      } else {
        await handleLeaderboardChange(leaderboardType);
      }
    };
    fetchData();
  }, [
    isDailyChallenge, 
    leaderboardType, 
    gameState, 
    handleLeaderboardChange, 
    fetchDailyChallengeData
  ]);

  const handleLeaderboardTypeClick = (type: "Least" | "Most" | "Streak") => {
    setLeaderboardType(type);
  }

  return (
    <div className='Leaderboard'>
      <div className="leaderboard-title">
        <LeaderboardIcon sx={{ fontSize: 20 }} />
        <h2>{isDailyChallenge ? 'Leaderboard' : 'Leaderboards'}</h2>
      </div>

      {(isDailyChallenge && dailyChallengeGameMode && dailyChallengePlayCount && dailyChallengePlayCount > 0) && (
        <div className="daily-challenge-info">
          {(isDailyChallenge && dailyChallengeGameMode) && <p className='daily-challenge-game-mode'>{dailyChallengeGameMode}</p>}
          <p>|</p>
          <div className="daily-challenge-players">
            <GroupsIcon sx={{ fontSize: 25 }} />
            <p>{dailyChallengePlayCount * 3}</p>
          </div>
        </div>
      )}

      {!isDailyChallenge && (
        <div className='leaderboard-types'>
          <button 
            onClick={() => handleLeaderboardTypeClick("Least")} 
            className={leaderboardType === "Least" ? 'selected' : ''}
          >
            Points
          </button>
          <button 
            onClick={() => handleLeaderboardTypeClick("Streak")} 
            className={leaderboardType === "Streak" ? 'selected' : ''}
          >
            Streak
          </button>
        </div>
      )}

      <div className="leaderboard-members">
        {loading && (
          <p>Loading leaderboard...</p>
        )}

        {!loading && isDailyChallenge && (
          <>
            {dailyChallengeMembers.length === 0 ? (
              <p className="no-data">No one has completed today's challenge yet!</p>
            ) : (
              dailyChallengeMembers.map((member, index) => (
                <LeaderboardMember 
                  key={index} 
                  member={member} 
                  rank={index + 1} 
                  isDailyChallenge={true}
                />
              ))
            )}
          </>
        )}

        {!loading && !isDailyChallenge && (
          <>
            {leaderboardMembers.length === 0 ? (
              <p className="no-data">No data available for this leaderboard.</p>
            ) : (
              leaderboardMembers.map((member, index) => (
                <LeaderboardMember 
                  key={index} 
                  member={member} 
                  rank={index + 1} 
                />
              ))
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Leaderboard