import React, { useEffect, useState } from 'react'

import './GameChoices.css'

import Choice from './Choice/Choice'

import { GameStateType } from '../../../../../types/GameStateType'
import { GameModeType } from '../../../../../types/GameModeType'

import { useAuth } from '../../../../../Context/AuthContext'
import { useCheckAnswer } from '../../../../../Hooks/useCheckAnswer'

import { getRandomOptions } from '../../../../../helpers/quizHelpers'
import { GameDifficultyType } from '../../../../../types/GameDifficultyType'

import { useGame } from '../../../../../Context/GameContext'

interface GameChoicesProps {
  gameMode: GameModeType,
  correctAnswer: string,
  quizId: string,
  nextQuiz: () => void,
  setGameState: (state: GameStateType) => void,
  gameDifficulty: GameDifficultyType,
  dailyChallengeMode?: GameModeType
}

const GameChoices: React.FC<GameChoicesProps> = ({
  gameMode, 
  correctAnswer, 
  quizId, 
  nextQuiz, 
  gameDifficulty,
  dailyChallengeMode
}) => {
  const { user } = useAuth();
  const { customCountries } = useGame();
  const { checkAnswer } = useCheckAnswer({
    correctAnswer, 
    quizId, 
    gameDifficulty, 
    nextQuiz,
    gameMode,
  });
  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    const result = getRandomOptions(correctAnswer, gameMode, gameDifficulty, customCountries, dailyChallengeMode);
    setOptions(result)
  }, [nextQuiz, correctAnswer, gameMode, gameDifficulty, customCountries, dailyChallengeMode])

  useEffect(() => {
    const choicesContainer = document.getElementById('GameChoices');
    choicesContainer?.focus();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const key = event.key;
      const optionNumber = parseInt(key, 10);

      if (!isNaN(optionNumber) && optionNumber >= 1 && optionNumber <= options.length) {
        event.preventDefault();
        checkAnswer(options[optionNumber - 1]);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [checkAnswer, options]);

  if (options && options.length >= 1) {
    return (
      <div id='GameChoices' className='GameChoices'>
        {options.map((option, index) => (
          <Choice
            key={`${index}-${option}`}
            country={option}
            checkAnswer={() => checkAnswer(option)}
            isCorrect={option === correctAnswer}
            showCorrect={user?.role === "admin"}
          />
        ))}
      </div>
    );
  }

  return <p>Loading options...</p>;
}

export default GameChoices