import React, { useState, useEffect } from 'react';
import './CustomGamemode.css';

import { flagMap } from '../../../../config/flagData';
import { quizOptionsObject } from '../../../../config/quizOptionsObject';
import { superRegions } from '../../../../config/superRegions';

import { useAuth } from '../../../../Context/AuthContext';
import { useGame } from '../../../../Context/GameContext';
import { usePremium } from '../../../../Context/PremiumContext';

import SpecialButton from '../../../SpecialButton/SpecialButton';

import { GameStateType } from '../../../../types/GameStateType';
import { GameModeType } from '../../../../types/GameModeType';

import { increaseGamemodePlayCountFirestore } from '../../../../services/firestoreService';
import BasicButton from '../../../BasicButton/BasicButton';
import { GameDifficultyType } from '../../../../types/GameDifficultyType';

interface CustomGamemodeProps {
  setGameState: (state: GameStateType) => void;
  gameMode: GameModeType;
  onCustomCountriesSelected: (countries: string[]) => void;
}

const CustomGamemode: React.FC<CustomGamemodeProps> = ({ setGameState, gameMode, onCustomCountriesSelected }) => {
  const { user } = useAuth();
  const { setGameDifficulty } = useGame();
  const { showPremiumModal } = usePremium();
  const isPremiumUser = user?.userType === "authenticated" && user?.subscription === "Premium";
  const isGuest = user?.userType === "anonymous";
  const [allCountries, setAllCountries] = useState<string[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectedDifficulty, setSelectedDifficulty] = useState<GameDifficultyType>(GameDifficultyType.Easy);

  const regionFilter = 'all'

  useEffect(() => {
    const formattedGameMode = gameMode.replace(/\s+/g, '');
    let countries: string[] = [];

    if (gameMode === GameModeType.Custom) {
      countries = Object.values(quizOptionsObject).flat();
    } else if (quizOptionsObject[formattedGameMode]) {
      countries = [...quizOptionsObject[formattedGameMode]];
    } else if (Object.keys(superRegions).includes(formattedGameMode)) {
      const regions = superRegions[formattedGameMode];
      regions.forEach(region => {
        if (quizOptionsObject[region]) {
          countries = [...countries, ...quizOptionsObject[region]];
        }
      });
    }

    const uniqueCountries = Array.from(new Set(countries));
    setAllCountries(uniqueCountries);
    setSelectedCountries(uniqueCountries.slice(0, 2));
  }, [gameMode]);

  const handleStart = () => {
    onCustomCountriesSelected(selectedCountries);
    setGameState(GameStateType.Playing);
    setGameDifficulty(selectedDifficulty);

    increaseGamemodePlayCountFirestore(gameMode);
  };
  
  const toggleCountry = (country: string) => {
    if (selectedCountries.includes(country)) {
      if (selectedCountries.length > 2) {
        setSelectedCountries(prev => prev.filter(c => c !== country));
      }
    } else {
      setSelectedCountries(prev => [...prev, country]);
    }
  };
  
  const filterCountries = () => {
    if (regionFilter === 'all') return allCountries;
    
    // Apply region filtering logic here if needed
    return allCountries;
  };

  const handleDifficultyClick = (difficulty: string) => {
    setSelectedDifficulty(difficulty as GameDifficultyType);
  };

  const handleUpgradeToPremium = () => {
    if (!isPremiumUser && !isGuest) {
      showPremiumModal();
    } else {
      handleStart();
    }
  }
  
  if (!isPremiumUser) {
    return (
      <div className="CustomGamemode premium-locked">
        <h2>Premium Feature</h2>
        <p>Custom Game Mode is a premium feature. Upgrade to Premium to access custom games.</p>
        <SpecialButton 
          text="Upgrade to Premium" 
          onClick={handleUpgradeToPremium} 
        />
      </div>
    );
  }
  
  return (
    <div className="CustomGamemode">
      <h2>Create Your Custom Game</h2>
      <p className="custom-gamemode-instructions">Select all the countries you want to include in your game. Minimum 2.</p>
      <p className="countries-count"><strong>Selected:</strong> {selectedCountries.length} / {allCountries.length}</p>
      
      
      <div className="countries-grid">
        {filterCountries().map(country => (
          <div 
            key={country}
            className={`country-item ${selectedCountries.includes(country) ? 'selected' : ''}`}
            onClick={() => toggleCountry(country)}
          >
            <img src={flagMap[country]} alt={country} />
            <span>{country}</span>
          </div>
        ))}
      </div>

      <div className="difficulty-options">
        {Object.values(GameDifficultyType).map(difficulty => (
          <BasicButton
            key={difficulty}
            text={difficulty.charAt(0).toUpperCase() + difficulty.slice(1).toLowerCase()}
            onClick={() => handleDifficultyClick(difficulty)}
            btnClass={`${difficulty} ${selectedDifficulty === difficulty ? ' selectedDifficulty' : ''}`}
          />
        ))}
      </div>

      <div className="custom-gamemode-controls">
        <SpecialButton text="Start Game" onClick={handleStart} />
      </div>
    </div>
  );
};

export default CustomGamemode;
