import React from 'react'

import './DailyChallengeInfo.css'

import { usePoints } from '../../../../Context/PointsContext';

import SpecialButton from '../../../SpecialButton/SpecialButton';

import { GameStateType } from '../../../../types/GameStateType';

import { superRegions } from '../../../../config/superRegions';
import { quizOptionsObject } from '../../../../config/quizOptionsObject';

import CountriesContainer from '../../../CountriesContainer/CountriesContainer';

import GroupsIcon from '@mui/icons-material/Groups';

interface DailyChallengeInfoProps {
  setGameState: (state: GameStateType) => void;
  setShowShareModal: (show: boolean) => void;
}

const DailyChallengeInfo: React.FC<DailyChallengeInfoProps> = ({ setGameState, setShowShareModal }) => {
  const { 
    dailyChallengeAnswers, 
    dailyChallengePlayedToday, 
    dailyChallengeTotalQuizzes, 
    dailyChallengeGameMode,
    dailyChallengePlayCount
  } = usePoints();

  const handleStartGame = async () => {
    setGameState(GameStateType.Playing);
  }

  const formattedGameMode = dailyChallengeGameMode.replace(/\s+/g, '');

  let displayCountries: string[] = [];
  let remainingCount = 0
  let isSuperRegion = false

  if (Object.keys(superRegions).includes(formattedGameMode)) {
    remainingCount = 0
    isSuperRegion = true
  } else {
    const countries = quizOptionsObject[formattedGameMode] || [];
    displayCountries = countries.slice(0, 4);
    remainingCount = countries.length > 4 ? countries.length - 4 : 0;
  }

  const correctAnswers = dailyChallengeAnswers.filter(answer => answer.correct).length;

  return (
    <div className='StartGame'>
      <div className="daily-challenge-section">
        <h3>Daily Challenge</h3>

        <div className="daily-challenge-section-header">
          <p>Today's challenge {dailyChallengePlayedToday ? 'was' : 'is'} <span>{dailyChallengeGameMode}</span></p>

          <CountriesContainer 
            isSuperRegion={isSuperRegion}
            gameMode={dailyChallengeGameMode}
            displayCountries={displayCountries}
            remainingCount={remainingCount}
          />
        </div>

        <div className="daily-challenge-players-container">
          <p>Players:</p>
          <p className='daily-challenge-players-count'>{dailyChallengePlayCount * 3}</p>
          <GroupsIcon sx={{ fontSize: 25 }} />
        </div>

        {dailyChallengePlayedToday ? (
          <div className="daily-challenge-result">
            <p>You got <span>{correctAnswers}/{dailyChallengeTotalQuizzes}</span> correct answers.</p>
            {dailyChallengeAnswers && dailyChallengeAnswers.length > 0 && (
              <div className="share-emoji-pattern">
                {dailyChallengeAnswers.map((answer, index) => (
                  <span key={index} className="emoji">
                    {answer.correct ? '🟩' : '🟥'}
                  </span>
                ))}
              </div>
            )}
          </div>
        ) : (
          <div className="share-emoji-pattern">
            {Array.from({ length: dailyChallengeTotalQuizzes }).map((_, index) => (
              <span key={index} className="emoji">
                ⬜️
              </span>
            ))}
          </div>
        )}

      </div>

      {!dailyChallengePlayedToday ? 
        <SpecialButton text='Play Daily Challenge' onClick={handleStartGame} />
      : 
        <SpecialButton text='Share Result' onClick={() => setShowShareModal(true)} />
      }
    </div>
  )
}

export default DailyChallengeInfo