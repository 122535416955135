import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import './SubscriptionCancel.css';

const SubscriptionCancel: React.FC = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    // Redirect back to home after a delay
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);
    
    return () => clearTimeout(timer);
  }, [navigate]);
  
  return (
    <div className="subscription-container">
      <h1>Process Cancelled</h1>
      <p>The subscription process has been cancelled. No charges have been made to your account.</p>
      <p>You will be redirected to the home page in a few seconds...</p>
    </div>
  );
};

export default SubscriptionCancel; 