import React from 'react'

import "./SpecialButton.css"

interface SpecialButtonProps {
  text: string;
  onClick: () => void;
  disabled?: boolean;
}

const SpecialButton: React.FC<SpecialButtonProps> = ({ text, onClick, disabled }) => {
  return (
    <button className='SpecialButton' onClick={onClick} disabled={disabled}>
      {text}
    </button>
  )
}

export default SpecialButton