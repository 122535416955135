import React from 'react'

import './ControlButton.css'

interface ControlButtonProps {
  text: string;
  icon?: React.ReactElement;
  onClick: () => void;
  className: string,
  disabled?: boolean,
}

const ControlButton: React.FC<ControlButtonProps> = ({ text, icon, onClick, className, disabled }) => {

  return (
    <button 
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      <p>{text}</p>
    </button>
  )
}

export default ControlButton