import React from 'react';
import { Helmet } from 'react-helmet-async';
import './Pages.css';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="legal-page">
      <Helmet>
        <title>Privacy Policy | Nationality Guesser</title>
        <meta name="description" content="Privacy Policy for Nationality Guesser web app. Learn how we collect, use, and protect your personal information." />
        <link rel="canonical" href="https://nationalityguesser.com/privacypolicy" />
      </Helmet>
      <div className="legal-container">
        <h1>Privacy Policy</h1>
        <p>Last updated: 17/03/2025</p>
        
        <section>
          <h2>1. Introduction</h2>
          <p>Welcome to Nationality Guesser ("we," "our," or "us"). We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our nationality guessing game web app.</p>
        </section>
        
        <section>
          <h2>2. Information We Collect</h2>
          <p>We collect the following types of information:</p>
          
          <h3>For Anonymous Accounts:</h3>
          <ul>
            <li>Username</li>
            <li>Login count</li>
            <li>Last login date</li>
            <li>Account creation date</li>
            <li>Game statistics (points and streak)</li>
            <li>Contact messages sent to us from you</li>
            <li>Reports sent to us from you</li>
          </ul>
          
          <h3>For Authenticated Accounts (via email or Gmail):</h3>
          <ul>
            <li>Username</li>
            <li>Email address</li>
            <li>Login count</li>
            <li>Last login date</li>
            <li>Account creation date</li>
            <li>Game statistics (points and streak)</li>
            <li>Contact messages sent to us from you</li>
            <li>Reports sent to us from you</li>
          </ul>
        </section>
        
        <section>
          <h2>3. How We Use Your Information</h2>
          <p>We use the information we collect to:</p>
          <ul>
            <li>Provide, maintain, and improve our services</li>
            <li>Manage your account and provide you with customer support</li>
            <li>Perform research and analysis about your use of our webapp</li>
            <li>Communicate with you about your account and game-related notifications</li>
          </ul>
        </section>
        
        <section>
          <h2>4. Data Retention</h2>
          <p>We will retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
        </section>
        
        <section>
          <h2>5. Data Protection Rights</h2>
          <p>As a resident of the European Union, you have certain data protection rights under the GDPR. These include the right to:</p>
          <ul>
            <li>Access your personal data</li>
            <li>Rectify inaccurate personal data</li>
            <li>Erase your personal data</li>
            <li>Restrict or object to the processing of your personal data</li>
            <li>Data portability</li>
            <li>Withdraw consent at any time</li>
          </ul>
          <p>To exercise these rights, please contact us at contact.help.ayr@gmail.com.</p>
        </section>
        
        <section>
          <h2>6. Data Security</h2>
          <p>We have implemented appropriate technical and organizational security measures to protect your personal information. However, please note that no method of transmission over the Internet or electronic storage is 100% secure.</p>
        </section>
        
        <section>
          <h2>7. Third-Party Services</h2>
          <p>Our web app may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third-party sites.</p>
        </section>
        
        <section>
          <h2>8. Children's Privacy</h2>
          <p>Our web app is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13.</p>
        </section>
        
        <section>
          <h2>9. Changes to This Privacy Policy</h2>
          <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.</p>
        </section>
        
        <section>
          <h2>10. Contact Us</h2>
          <p>If you have any questions about this Privacy Policy or wish to exercise your data protection rights, please contact us at:</p>
          <p>Email: contact.help.ayr@gmail.com</p>
        </section>
        
        <section>
          <h2>11. Supervisory Authority</h2>
          <p>If you are a resident of the European Union and believe we have not adequately addressed your data protection concerns, you have the right to lodge a complaint with your local data protection authority.</p>
        </section>
        
        <section>
          <h2>12. Reward Ad System</h2>
          <p>Our web app includes a reward ad system that allows users to earn "streak savers" by watching ads. This feature:</p>
          <ul>
            <li>Is entirely optional and user-initiated</li>
            <li>Involves the display of third-party advertisements</li>
            <li>May collect additional data as outlined in Section 2</li>
            <li>Is provided through our advertising partner(s) as listed in Section 7</li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy; 