import React from 'react';

import "./GameEnded.css"

import { flagMap } from '../../../../../config/flagData';

import ReplayIcon from '@mui/icons-material/Replay';
import ShareIcon from '@mui/icons-material/Share';

import { GameModeType } from '../../../../../types/GameModeType';

interface GameEndedProps {
  handlePlayAgain: () => void,
  handleShare: () => void;
  correctAnswer: string,
  gameMode: GameModeType,
}

const GameEnded: React.FC<GameEndedProps> = ({ handlePlayAgain, correctAnswer, gameMode, handleShare }) => {
  const flagImage = flagMap[correctAnswer];

  const dailyGameMode = gameMode === GameModeType.DailyChallenge;
  
  return (
    <div className="gameEndedContainer">
      {!dailyGameMode && (
        <div className="end-text-container">
          <p>Incorrect, the answer was <span>{correctAnswer}</span>.</p>
          <img src={flagImage} alt={`${correctAnswer} flag`} />
        </div>
      )}

      <div className='gameEndedButtons'>
        <button className='gameShareButton' onClick={handleShare}>
          <ShareIcon sx={{ fontSize: 24 }} />
          <p>Share Result</p>
        </button>

        {!dailyGameMode && (
          <button className='gamePlayAgain' onClick={handlePlayAgain}>
            <ReplayIcon sx={{ fontSize: 24 }} />
            <p>Play Again</p>
          </button>
        )}
      </div>
    </div>
  )
}

export default GameEnded