import React, { useEffect, useState } from 'react'

import "./Game.css"

import { useGame } from '../../../Context/GameContext'

import Instructions from './Instructions/Instructions'
import Playing from './Playing/Playing'
import StartGame from './StartGame/StartGame'
import CustomGamemode from './CustomGamemode/CustomGamemode'
import DailyChallengeInfo from './DailyChallengeInfo/DailyChallengeInfo'

import { GameModeType } from '../../../types/GameModeType'
import { GameStateType } from '../../../types/GameStateType'
import ShareModal from './Playing/GameEnded/ShareModal/ShareModal'

interface GameProps {
  setOpenLoginModal: (open: boolean) => void;
}

const Game: React.FC<GameProps> = ({ setOpenLoginModal }) => {
  const { gameState, setGameState, gameDifficulty, gameMode, setCustomCountries } = useGame();

  const [fullWidth, setFullWidth] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 800);

  const [showShareModal, setShowShareModal] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState<string>("");
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (gameState === GameStateType.Playing && !isMobile) {
      setFullWidth(true)
    } else {
      setFullWidth(false)
    }
  }, [gameState, isMobile])

  const handleStartGameFromInstructions = () => {
    if (gameMode === GameModeType.Custom) {
      setGameState(GameStateType.CustomMode);
    } else if (gameMode === GameModeType.DailyChallenge) {
      setGameState(GameStateType.DailyChallenge);
    } else {
      setGameState(GameStateType.Start);
    }
  };

  const handleCustomCountriesSelected = (countries: string[]) => {
    setCustomCountries(countries);
  };

  return (
    <div className={`Game ${(fullWidth) ? "fullWidthGame" : ""}`}>
      {gameState === GameStateType.Instructions && (
        <Instructions handleStartGameFromInstructions={handleStartGameFromInstructions} />
      )}

      {gameState === GameStateType.Start && (
        <StartGame setGameState={setGameState} setOpenLoginModal={setOpenLoginModal} gameDifficulty={gameDifficulty} gameMode={gameMode} />
      )}
      
      {gameState === GameStateType.DailyChallenge && (
        <DailyChallengeInfo setGameState={setGameState} setShowShareModal={setShowShareModal} />
      )}

      {gameState === GameStateType.CustomMode && (
        <CustomGamemode 
          setGameState={setGameState} 
          gameMode={gameMode} 
          onCustomCountriesSelected={handleCustomCountriesSelected} 
        />
      )}

      {(gameState === GameStateType.Playing || gameState === GameStateType.End) && (
        <Playing showShareModal={showShareModal} setShowShareModal={setShowShareModal} setCorrectAnswer={setCorrectAnswer} />
      )}

      {showShareModal && (
        <ShareModal 
          correctAnswer={correctAnswer} 
          onClose={() => setShowShareModal(false)} 
        />
      )}
    </div>
  )
}

export default Game