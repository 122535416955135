import React, { useState } from 'react';

import './PremiumModal.css';

import SpecialButton from '../SpecialButton/SpecialButton';

import { subscribeUserToPremium } from '../../services/subscriptionService';

import { User } from '../../types/UserType';
import { logSubscriptionAttempt } from '../../services/firestoreService';

interface PremiumModalProps {
  setOpenPremiumModal: (open: boolean) => void;
  showAlert: (message: string, status: 'red' | 'green' | 'blue' | null) => void;
  user: User | null;
}

const PremiumModal: React.FC<PremiumModalProps> = ({ setOpenPremiumModal, showAlert, user }) => {
  const [isLoading, setIsLoading] = useState(false);
  
  const handleContainerClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleSubscribe = async () => {
    if (!user) {
      console.error('User is required for subscription');
      return;
    }

    showAlert("Redirecting soon to payment page...", "blue");
    setIsLoading(true);
    
    try {
      // Log the subscription attempt
      await logSubscriptionAttempt(user);
      
      await subscribeUserToPremium(user.uid);
    } catch (error) {
      console.error('Error during subscription process:', error);
      setIsLoading(false);
    }
  };

  return (
    <div className='PremiumModal' onClick={() => setOpenPremiumModal(false)}>
      <div className="PremiumModal-container" onClick={handleContainerClick}>
        <h2>Upgrade to Premium</h2>
        <div className="premium-features">
          <h3>Premium Features:</h3>
          <div className='premium-feature-container'>
            <p className='premium-feature-title'>✔︎ Custom Game Mode</p>
            <p className='premium-feature-description'>Create your own game mode with your own countries</p>
          </div>
          <div className='premium-feature-container'>
            <p className='premium-feature-title'>✔︎ Access to all gamemodes</p>
            <p className='premium-feature-description'>Play all the gamemodes without any limitations</p>
          </div>
          <div className='premium-feature-container'>
            <p className='premium-feature-title'>✔︎ 5 Skips per game</p>
            <p className='premium-feature-description'>Skip the current quizz and get a different one</p>
          </div>
          <div className='premium-feature-container'>
            <p className='premium-feature-title'>✔︎ Streak Saver</p>
            <p className='premium-feature-description'>Save your streak and continue the game after a mistake</p>
          </div>
          <div className='premium-feature-container'>
            <p className='premium-feature-title'>✔︎ Ad-Free Experience</p>
            <p className='premium-feature-description'>No ads during the game</p>
          </div>
        </div>
        <div className="premium-pricing">
          <h3>€3.99/month</h3>
          <p>Cancel anytime</p>
        </div>
        <SpecialButton 
          text={isLoading ? "Loading..." : "Let's go!"} 
          onClick={handleSubscribe}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

export default PremiumModal; 