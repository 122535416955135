import React, { useState } from 'react';
import './FeedbackModal.css';

import { submitFeedback } from '../../../services/firestoreService';

import { useAuth } from '../../../Context/AuthContext';

import BasicButton from '../../BasicButton/BasicButton';

interface FeedbackModalProps {
  setOpenFeedbackModal: (open: boolean) => void;
  showAlert: (message: string, status: 'red' | 'green' | 'blue' | null) => void;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ setOpenFeedbackModal, showAlert }) => {
  const { user } = useAuth();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const handleContainerClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleSubmit = async () => {
    if (!message.trim()) {
      showAlert("Please enter your feedback message", "red");
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      await submitFeedback({
        userId: user?.uid,
        username: user?.username,
        email: email.trim() || user?.email || "",
        message: message.trim(),
        subscription: user?.subscription || "",
        userType: user?.userType || ""
      });
      
      showAlert("Thank you for your feedback!", "green");
      setOpenFeedbackModal(false);
    } catch (error) {
      console.error('Error submitting feedback:', error);
      showAlert("Failed to submit feedback. Please try again.", "red");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className='FeedbackModal' onClick={() => setOpenFeedbackModal(false)}>
      <div className="FeedbackModal-container" onClick={handleContainerClick}>
        <h2>Give us Feedback</h2>
        <p className="feedback-description">We value your feedback! Let us know how we can improve.</p>
        
        <div className="feedback-form">
          <div className="form-group">
            <label htmlFor="email">Your email <span className='optional-text'>(optional)</span></label>
            <input
              type="email" 
              id="email"
              placeholder="Enter your email for a reply"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="message">Your message</label>
            <textarea
              id="message"
              placeholder="Tell us what's on your mind..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows={5}
            />
          </div>
        </div>
        
        <BasicButton 
          text={isSubmitting ? "Sending..." : "Submit Feedback"} 
          onClick={handleSubmit}
          btnClass={"SubmitButton2"}
          disabled={isSubmitting || !message.trim()}
        />
        
        <p className="contact-email">
          You can also contact us by emailing <a href="mailto:contact.help.ayr@gmail.com">contact.help.ayr@gmail.com</a>
        </p>
      </div>
    </div>
  );
};

export default FeedbackModal; 