import { GameModeType } from '../types/GameModeType';
import { superRegions } from '../config/superRegions';
import { User } from '../types/UserType';

export const canAccessGamemode = (gameMode: GameModeType, user: User | null): boolean => {
  if (!user) return false;
  
  // DailyChallenge is accessible to everyone
  if (gameMode === GameModeType.DailyChallenge) return true;
  
  const { userType, subscription } = user;
  
  const isSuperRegion = Object.keys(superRegions).includes(gameMode);
  
  // Guest users can only access Daily Challenge
  if (userType === "anonymous") {
    return false;
  }
  
  // Authenticated users with any subscription can access super regions
  if (isSuperRegion && userType === "authenticated") {
    return true;
  }
  
  // Only premium users can access specific regions
  if (!isSuperRegion && userType === "authenticated" && subscription === "Premium") {
    return true;
  }
  
  return false;
}; 