import React, { useEffect, useState } from 'react'

import './GameControlBar.css'

import PointsStreak from '../../Statistics/PointsStreak/PointsStreak';

import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import GppGoodIcon from '@mui/icons-material/GppGood';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import InfoIcon from '@mui/icons-material/Info';

import ControlButton from './ControlButton/ControlButton';
import MessageSubmitModal from '../../../../MessageSubmitModal/MessageSubmitModal';

import { usePoints } from '../../../../../Context/PointsContext';
import { useAuth } from '../../../../../Context/AuthContext';
import { useGame } from '../../../../../Context/GameContext';

import { Quiz } from '../../../../../types/Quiz';

import { increaseWrongGuess } from '../../../../../services/firestoreService';

import Skip from '../../../../../assets/icons/Skip-white-bg.png'
import { GameStateType } from '../../../../../types/GameStateType';
import { GameModeType } from '../../../../../types/GameModeType';
import { useCheckAnswer } from '../../../../../Hooks/useCheckAnswer';

interface GameControlProps {
  nextQuiz: () => void,
  currentQuiz: Quiz;
}

const GameControlBar: React.FC<GameControlProps> = ({ nextQuiz, currentQuiz }) => {
  const { skipCount, currentPoints, gameStreak, handleSkipCountUsed, currentLevelName, usedStreakSaver } = usePoints()
  const { gameState, gameMode, gameDifficulty } = useGame()
  const { user } = useAuth();
  
  const isPremiumUser = user?.userType === "authenticated" && user?.subscription === "Premium";

  const [skipsExhausted, setSkipsExhausted] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showSkipInfo, setShowSkipInfo] = useState(false);
  const [showStreakSaverInfo, setShowStreakSaverInfo] = useState(false);

  const { handleDailyChallengeSkip } = useCheckAnswer({
    correctAnswer: currentQuiz?.country || '',
    quizId: currentQuiz?.id || '',
    gameDifficulty,
    nextQuiz,
    gameMode
  });

  useEffect(() => {
    if (skipCount < 1) {
      setSkipsExhausted(true)
    } else {
      setSkipsExhausted(false)
    }
  }, [skipCount])

  const handleToggleModal = () => {
    setOpenModal(current => !current)
  }

  const handleSkipClick = () => {
    if (skipCount >= 1) {
      if (gameMode === GameModeType.DailyChallenge && user?.uid) {
        handleDailyChallengeSkip();
      } else {
        nextQuiz();
        increaseWrongGuess(currentQuiz.id);
      }
      handleSkipCountUsed();
    }
  }

  const shouldShowPoints = gameMode !== GameModeType.DailyChallenge;

  return (
    <div className='GameControlBar'>
      <div className="modalContainer">
        <ControlButton 
          text={"Report"} 
          icon={<ReportGmailerrorredIcon sx={{ fontSize: 20 }} />}
          onClick={handleToggleModal}
          className={'controlButton'}
        />
        {openModal && <MessageSubmitModal handleToggleModal={handleToggleModal} currentQuiz={currentQuiz} />}
      </div>

      <div className="streak-container">
        <PointsStreak highscoreStreak={gameStreak} imageType={"streakWhiteBorder"} maxxed={true} />
        <div 
          className={`streak-saver ${(usedStreakSaver || !isPremiumUser) ? 'used' : 'available'}`}
          onMouseEnter={() => setShowStreakSaverInfo(true)} 
          onMouseLeave={() => setShowStreakSaverInfo(false)}
        >
          {(usedStreakSaver || !isPremiumUser) ? <RemoveModeratorIcon className='streak-saver-icon' sx={{ fontSize: 28 }} /> : <GppGoodIcon className='streak-saver-icon' sx={{ fontSize: 30 }} />}
          {showStreakSaverInfo && (
            <div className="streak-saver-tooltip">
              <div className="tooltip-header">
                <InfoIcon sx={{ fontSize: 16 }} />
                <span>Streak Savers</span>
              </div>
              <ul>
                <li>
                  <span className="user-type">Guest:</span>
                  <span className="skip-count">0 savers</span>
                </li>
                <li>
                  <span className="user-type">Signed-in:</span>
                  <span className="skip-count">0 savers</span>
                </li>
                <li>
                  <span className="user-type">Premium:</span>
                  <span className="skip-count">1 saver</span>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      
      {shouldShowPoints && (
        <PointsStreak highscoreStreak={currentPoints} imageType={"pointsWhiteBorder"} maxxed={true} currentLevelName={currentLevelName} />
      )}
      
      <div className="skip-button-container" onMouseEnter={() => setShowSkipInfo(true)} onMouseLeave={() => setShowSkipInfo(false)}>
        <ControlButton 
          text={skipCount > 1 ? `${skipCount} Skips` : "Skip"}
          icon={<img src={Skip} alt="skip" className='skip-icon' />}
          onClick={handleSkipClick}
          className={skipsExhausted ? 'controlButtonLocked' : 'controlButton'}
          disabled={skipsExhausted || gameState !== GameStateType.Playing}
        />
        {showSkipInfo && (
          <div className="skip-info-tooltip">
            <div className="tooltip-header">
              <InfoIcon sx={{ fontSize: 16 }} />
              <span>Skips Available</span>
            </div>
            <ul>
              <li>
                <span className="user-type">Guest:</span>
                <span className="skip-count">0 skips</span>
              </li>
              <li>
                <span className="user-type">Signed-in:</span>
                <span className="skip-count">1 skip</span>
              </li>
              <li>
                <span className="user-type">Premium:</span>
                <span className="skip-count">5 skips</span>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default GameControlBar