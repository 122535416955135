import React, { useEffect, useState } from 'react'

import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FaceIcon from '@mui/icons-material/Face';

import { useAuth } from '../../Context/AuthContext';
import { useAlert } from '../../Context/AlertContext';

import './ProfileModal.css'

import BasicButton from '../BasicButton/BasicButton';

interface ProfileModalProps {
  setOpenProfileModal: (open: boolean) => void;
}

const ProfileModal: React.FC<ProfileModalProps> = ({ setOpenProfileModal }) => {
  const { user, signOut, updateUserState } = useAuth();
  const { showAlert } = useAlert()

  const [editingUsername, setEditingUsername] = useState<boolean>(false);
  const [username, setUsername] = useState<string | null>(null);

  useEffect(() => {
    if (user) {
      setUsername(user.username);
    }
  }, [user]);

  const handleContainerClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleSignOut = () => {
    signOut()
    setOpenProfileModal(false)
  }

  const handleEditingUsername = () => {
    setEditingUsername(!editingUsername);
  };
  
  const handleClearButton = () => {
    if (user) setUsername(user.username);
    setEditingUsername(false);
  };

  const confirmEditUsername = async (newUsername: string) => {
    newUsername = newUsername.trim();
    if (!newUsername) {
      showAlert("Username cannot be empty", "blue")
      return;
    }
    if (newUsername.length >= 15) {
      showAlert('Username must be shorter than 15 characters.', "blue");
      return;
    }
    if (newUsername !== user?.username && user) {
      const updatedUser = {
        ...user,
        username: newUsername
      };
     
      setEditingUsername(false);
      await updateUserState(updatedUser);
    }
    setEditingUsername(false);
  };

  return (
    <div className='ProfileModal' onClick={() => setOpenProfileModal(false)}>
      <div className="profile-container" onClick={handleContainerClick}>
        <div className="profile-top">
          <div className="profile-top-left">
            <FaceIcon sx={{ fontSize: 20, color: '#fff' }} />
            <h3>Your profile</h3>
          </div>
          <button onClick={() => setOpenProfileModal(false)}>
            <CloseOutlinedIcon sx={{ fontSize: 24, color: '#000' }} />
          </button>
        </div>

        <div className="profile-row">
          <div className="profile-username-element">
            {editingUsername ? (
              <input 
                autoFocus
                type="text" 
                value={username ?? ""}
                onChange={(e) => setUsername(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
              />
            ) : (
              <p>{username}</p>
            )}
          </div>

          {!editingUsername && (
            <button className={"EditButton"} onClick={handleEditingUsername} >
              { <EditIcon sx={{ fontSize: 24 }}/>}
            </button>
          )}

          {editingUsername && (
            <button className={"ConfirmButton"} onClick={() => confirmEditUsername(username || "")} >
              { <CheckIcon sx={{ fontSize: 24 }}/>}
            </button>
          )}

          {editingUsername && (
            <button className={"clearButton"} onClick={handleClearButton} >
              {<CloseOutlinedIcon sx={{ fontSize: 24 }}/>}
            </button>
          )}
        </div>

        <BasicButton text='Logout' icon={<ExitToAppIcon sx={{ fontSize: 20 }} />} onClick={handleSignOut} />
      </div>
    </div>
  )
}

export default ProfileModal