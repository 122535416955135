import React, { createContext, useContext, useState, ReactNode } from 'react';

import AlertElement from '../components/AlertElement/AlertElement';

interface Alert {
  id: number;
  message: string;
  status: 'red' | 'green' | 'blue' | null;
}

interface AlertContextProps {
  showAlert: (message: string, status: 'red' | 'green' | 'blue' | null) => void;
  hideAlert: (id: number) => void;
}

const AlertContext = createContext<AlertContextProps>({
  showAlert: () => {},
  hideAlert: () => {},
});

interface AlertProviderProps {
  children: ReactNode;
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const showAlert = (message: string, status: 'red' | 'green' | 'blue' | null) => {
    const id = Date.now();
    const newAlert = { id, message, status };
    setAlerts(prev => [...prev, newAlert]);
    
    // Auto-dismiss alert after 3 seconds
    setTimeout(() => hideAlert(id), 3000);
  };

  const hideAlert = (id: number) => {
    setAlerts(prev => prev.filter(alert => alert.id !== id));
  };

  return (
    <AlertContext.Provider value={{ showAlert, hideAlert }}>
      {children}
      <div className="alerts-container" style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000 }}>
        {alerts.map(alert => (
          <div key={alert.id} style={{ marginBottom: '10px' }}>
            <AlertElement message={alert.message} status={alert.status} />
          </div>
        ))}
      </div>
    </AlertContext.Provider>
  );
};

export const useAlert = () => useContext(AlertContext);
