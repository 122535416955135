import React, { useState, useEffect } from 'react'

import './Navbar.css';

import ProgressBar from '../ProgressBar/ProgressBar';
import AYRLogo from '../AYRLogo/AYRLogo';

import ProfileElement from './ProfileElement/ProfileElement';
import SubscribeToPremiumButton from '../SubscribeToPremiumButton/SubscribeToPremiumButton';
import PremiumElement from '../PremiumElement/PremiumElement';
import Feedback from '../Feedback/Feedback';

import { GameStateType } from '../../types/GameStateType';

import { useGame } from '../../Context/GameContext';
import { useAuth } from '../../Context/AuthContext';
import { usePremium } from '../../Context/PremiumContext';
import { useAlert } from '../../Context/AlertContext';

import { manageSubscription } from '../../services/subscriptionService';

import Notifications from './Notifications/Notifications';
import { GameModeType } from '../../types/GameModeType';
interface NavbarProps {
  setOpenLoginModal: (open: boolean) => void;
  setOpenProfileModal: (open: boolean) => void;
}

const Navbar: React.FC<NavbarProps> = ({ setOpenLoginModal, setOpenProfileModal }) => {
  const { gameState, gameMode } = useGame()
  const { user } = useAuth();
  const { showAlert } = useAlert();
  const { showPremiumModal } = usePremium();
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 950);
  const [removeLoginText, setRemoveLoginText] = useState(window.innerWidth < 420);

  const isHomePage = window.location.pathname === "/";
  const isAuthenticated = user?.userType === "authenticated";
  const isPremiumUser = isAuthenticated && user?.subscription === "Premium";
  const isGuest = user?.userType === "anonymous";
  const isDailyChallenge = gameMode === GameModeType.DailyChallenge;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 950);
      if (window.innerWidth < 390) {
        setRemoveLoginText(true)
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (gameState === GameStateType.Playing) {
      setIsPlaying(true)
    } else {
      setIsPlaying(false)
    }
  }, [gameState])

  const managePremiumModal = () => {
    if (isPremiumUser) {
      showAlert("Loading subscription management...", "blue");
      manageSubscription().catch(error => {
        console.error('Failed to open subscription management:', error);
      });
    } else if (isGuest) {
      setOpenLoginModal(true);
    } else {
      showPremiumModal();
    }
  }

  return (
    <nav className="Navbar">
      {isMobile ? (
        <div className="mobile-navbar">
          <div className="mobile-top">
            <div className="left-nav-container">
              <Notifications />
              <Feedback />
              {isPremiumUser ? (
                <PremiumElement onClick={managePremiumModal} />
              ) : (
                (isAuthenticated && <SubscribeToPremiumButton onClick={managePremiumModal} isGuest={isGuest}/>)
              )}
            </div>

            <ProfileElement iconSize={20} setOpenLoginModal={setOpenLoginModal} setOpenProfileModal={setOpenProfileModal} removeLoginText={removeLoginText} />
            </div>

          <div className='nav-middle'>
            <AYRLogo />
            {(isHomePage && !isDailyChallenge) && (
              <ProgressBar />
            )}
          </div>
        </div>
      ) : (
        <div style={{ alignItems: isPlaying ? 'center' : 'start'}} className="desktop-navbar">
          <div className="left-nav-container">
            <Notifications />
            <Feedback />
            {isPremiumUser ? (
              <PremiumElement onClick={managePremiumModal} />
            ) : (
              (isAuthenticated && <SubscribeToPremiumButton onClick={managePremiumModal} isGuest={isGuest}/>)
            )}
          </div>

          <div className='nav-middle'>
            {(isPlaying && !isDailyChallenge) ? (
              <ProgressBar />
            ) : (
              <AYRLogo />
            )}
          </div>

          <ProfileElement iconSize={20} setOpenLoginModal={setOpenLoginModal} setOpenProfileModal={setOpenProfileModal} removeLoginText={removeLoginText} />
        </div>
      )}

    </nav>
  )
}

export default Navbar;