import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';

interface PremiumContextType {
  showPremiumModal: () => void;
  hidePremiumModal: () => void;
  isPremiumModalOpen: boolean;
}

const PremiumContext = createContext<PremiumContextType | undefined>(undefined);

interface PremiumProviderProps {
  children: ReactNode;
}

export const PremiumProvider: React.FC<PremiumProviderProps> = ({ children }) => {
  const [isPremiumModalOpen, setIsPremiumModalOpen] = useState(false);

  const showPremiumModal = useCallback(() => {
    setIsPremiumModalOpen(true);
  }, []);

  const hidePremiumModal = useCallback(() => {
    setIsPremiumModalOpen(false);
  }, []);

  const contextValue: PremiumContextType = {
    showPremiumModal,
    hidePremiumModal,
    isPremiumModalOpen,
  };

  return (
    <PremiumContext.Provider value={contextValue}>
      {children}
    </PremiumContext.Provider>
  );
};

export const usePremium = () => {
  const context = useContext(PremiumContext);
  if (context === undefined) {
    throw new Error('usePremium must be used within a PremiumProvider');
  }
  return context;
}; 