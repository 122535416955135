import React from 'react'

import './StartGame.css'

import { difficultyOptions } from '../../../../config/gameDifficultyOptions';

import { usePoints } from '../../../../Context/PointsContext';
import { useAuth } from '../../../../Context/AuthContext';
import { usePremium } from '../../../../Context/PremiumContext';

import SpecialButton from '../../../SpecialButton/SpecialButton';
import DifficultyChoice from '../../DifficultyChoice/DifficultyChoice';
import Gamemode from '../../Gamemodes/Gamemode/Gamemode';

import { GameDifficultyType } from '../../../../types/GameDifficultyType';
import { GameStateType } from '../../../../types/GameStateType';
import { GameModeType } from '../../../../types/GameModeType';

import PointsStreak from '../Statistics/PointsStreak/PointsStreak';

import { increaseGamemodePlayCountFirestore } from '../../../../services/firestoreService';
import { canAccessGamemode } from '../../../../utils/accessControl';

interface StartGameProps {
  setGameState: (state: GameStateType) => void;
  setOpenLoginModal: (open: boolean) => void;
  gameDifficulty: GameDifficultyType;
  gameMode: GameModeType;
}

const StartGame: React.FC<StartGameProps> = ({ setGameState, setOpenLoginModal, gameDifficulty, gameMode }) => {
  const { currentPoints, highscoreStreak, currentLevelName } = usePoints();
  const { user } = useAuth();
  const { showPremiumModal } = usePremium();

  const selectedDifficulty = difficultyOptions.find(
    (option) => option.value === gameDifficulty
  );
  const selectedDifficultyLabel = selectedDifficulty?.label || 'Unknown';
  const selectedDifficultyType = selectedDifficulty?.value || GameDifficultyType.Medium;

  const isGuest = user?.userType === "anonymous";

  const handleStartGame = async () => {
    if (!canAccessGamemode(gameMode, user)) {
      if (isGuest) {
        setOpenLoginModal(true);
      } else {
        showPremiumModal();
      }
      return;
    }

    setGameState(GameStateType.Playing);
    await increaseGamemodePlayCountFirestore(gameMode);
  }

  return (
    <div className='StartGame'>
      <div className="startgame-section">
        <h3>Current Gamemode</h3>

        <div className="settings-container">
          <Gamemode 
            gameMode={gameMode}
            className='GamemodeSelected'
            onClick={() => {}}
            disabled={true}
          />

          <DifficultyChoice 
            gameDifficulty={gameDifficulty}
            value={selectedDifficultyType}
            label={selectedDifficultyLabel} 
            disabled={true}
          />
        </div>
      </div>

      <div className="startgame-section">
        <h3>Your Stats</h3>

        <div className="stats-container">
          <PointsStreak highscoreStreak={highscoreStreak} imageType={"streakWhiteBorder"} />
          <PointsStreak highscoreStreak={currentPoints} imageType={"pointsWhiteBorder"} currentLevelName={currentLevelName} />
        </div>
      </div>

      <SpecialButton text='Start' onClick={handleStartGame} />
    </div>
  )
}

export default StartGame