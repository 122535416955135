import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';

import { usePointsSystem } from '../Hooks/usePointsSystem';

import { useAuth } from './AuthContext';

import { useGame } from './GameContext';
import { useAlert } from './AlertContext';
import { DailyChallengeAnswer } from '../types/DailyChallengeType';
import { checkUserDailyChallengeStatus, getDailyChallengeDocument, saveDailyChallengeResults } from '../services/dailyChallengeService';
import { GameModeType } from '../types/GameModeType';

interface PointsSystemContext {
  currentPoints: number;
  previousLevelPoints: number;
  nextLevelPoints: number;
  currentLevelName: string;
  gameStreak: number;
  previousGameStreak: number;
  previousGamePoints: number;
  highscoreStreak: number;
  lastAnswer: boolean | null;
  skipCount: number;
  streakSaverCount: number;
  usedStreakSaver: boolean;
  dailyChallengeAnswers: DailyChallengeAnswer[];
  dailyChallengeId: string | null;
  dailyChallengePlayedToday: boolean;
  dailyChallengePlayCount: number;
  dailyChallengeTotalQuizzes: number;
  dailyChallengeGameMode: GameModeType;
  recordDailyChallengeAnswer: (quizId: string, correct: boolean, skipped: boolean) => Promise<void>;
  handleSkipCountUsed: () => void;
  handleCorrectAnswer: (quizId: string, difficulty: string) => void;
  handleIncorrectAnswer: (quizId: string, difficulty: string) => void;
  handleChangeInGamemodeDifficulty: () => void;
  setStreakSaverCount: (count: number) => void;
  setUsedStreakSaver: (used: boolean) => void;
}

const defaultContext: PointsSystemContext = {
  currentPoints: 0,
  previousLevelPoints: 0,
  nextLevelPoints: 50,
  currentLevelName: '1',
  gameStreak: 0,
  previousGameStreak: 0,
  previousGamePoints: 0,
  highscoreStreak: 0,
  lastAnswer: null,
  skipCount: 1,
  streakSaverCount: 0,
  usedStreakSaver: false,
  dailyChallengeAnswers: [],
  dailyChallengeId: null,
  dailyChallengePlayedToday: false,
  dailyChallengePlayCount: 0,
  dailyChallengeTotalQuizzes: 0,
  dailyChallengeGameMode: GameModeType.Asia,
  recordDailyChallengeAnswer: async () => {},
  handleSkipCountUsed: () => {},
  handleCorrectAnswer: (quizId: string, difficulty: string) => {},
  handleIncorrectAnswer: (quizId: string, difficulty: string) => {},
  handleChangeInGamemodeDifficulty: () => {},
  setStreakSaverCount: () => {},
  setUsedStreakSaver: () => {}
};

const PointsContext = createContext<PointsSystemContext>(defaultContext);

interface PointsProviderProps {
  children: ReactNode;
}

export const PointsProvider: React.FC<PointsProviderProps> = ({ children }) => {
  const { user } = useAuth();
  const { gameMode, setGameDifficulty, setGameState } = useGame();
  const { showAlert } = useAlert();

  const [dailyChallengeAnswers, setDailyChallengeAnswers] = useState<DailyChallengeAnswer[]>([]);
  const [dailyChallengeId, setDailyChallengeId] = useState<string | null>(null);
  const [dailyChallengePlayedToday, setDailyChallengePlayedToday] = useState(false);
  const [dailyChallengeTotalQuizzes, setDailyChallengeTotalQuizzes] = useState(0);
  const [dailyChallengeGameMode, setDailyChallengeGameMode] = useState<GameModeType>(GameModeType.Asia);
  const [dailyChallengePlayCount, setDailyChallengePlayCount] = useState(0);
  let userAllowedSkips = 0;
  let streakSaverCount = 0;
  
  if (user?.userType === "anonymous") {
    userAllowedSkips = 0;
    streakSaverCount = 0;
  } else if (user?.userType === "authenticated") {
    if (user.subscription === "Premium") {
      userAllowedSkips = 5;
      streakSaverCount = 1;
    } else { // Standard subscription
      userAllowedSkips = 1;
      streakSaverCount = 0;
    }
  }

  const pointsHook = usePointsSystem(
    user?.uid || "", 
    gameMode, 
    userAllowedSkips, 
    streakSaverCount,
    setGameDifficulty, 
    setGameState,
    showAlert,
  );

  useEffect(() => {
    const initializeDailyChallenge = async () => {
      if (!user?.uid) return;
      
      try {
        const dailyChallenge = await getDailyChallengeDocument();
        setDailyChallengeId(dailyChallenge.id);
        setDailyChallengeTotalQuizzes(dailyChallenge.quizIds?.length || 0);
        setDailyChallengeGameMode(dailyChallenge.gameMode);
        setDailyChallengePlayCount(dailyChallenge.playCount || 0);
        
        const status = await checkUserDailyChallengeStatus(user.uid);
        setDailyChallengePlayedToday(status.played);
        
        if (status.played && status.results) {
          setDailyChallengeAnswers(status.results.answers || []);
        } else {
          setDailyChallengeAnswers([]);
        }
      } catch (error) {
        console.error('Error initializing daily challenge in context:', error);
      }
    };
    
    initializeDailyChallenge();
  }, [user?.uid]);

  const recordDailyChallengeAnswer = async (quizId: string, correct: boolean, skipped: boolean) => {
    if (!user?.uid || !dailyChallengeId) return;
    
    try {
      const newAnswer: DailyChallengeAnswer = { quizId, correct, skipped };
      const updatedAnswers = [...dailyChallengeAnswers, newAnswer];
      setDailyChallengeAnswers(updatedAnswers);
      
      if (updatedAnswers.length >= dailyChallengeTotalQuizzes) {
        await saveDailyChallengeResults(user.uid, updatedAnswers);
        setDailyChallengePlayedToday(true);
      }
    } catch (error) {
      console.error('Error recording daily challenge answer:', error);
    }
  };

  return (
    <PointsContext.Provider value={{
      ...pointsHook,
      dailyChallengeAnswers,
      dailyChallengeId,
      dailyChallengePlayedToday,
      dailyChallengePlayCount,
      dailyChallengeTotalQuizzes,
      dailyChallengeGameMode,
      recordDailyChallengeAnswer
    }}>
      {children}
    </PointsContext.Provider>
  );
};

export const usePoints = () => useContext(PointsContext);
