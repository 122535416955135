import { useCallback } from 'react';

import { usePoints } from '../Context/PointsContext';
import { useAuth } from '../Context/AuthContext';
import { useAlert } from '../Context/AlertContext';

import { GameModeType } from '../types/GameModeType';

interface UseCheckAnswerArgs {
  correctAnswer: string;
  quizId: string;
  gameDifficulty: string;
  nextQuiz: () => void;
  gameMode?: GameModeType;
}

export const useCheckAnswer = ({
  correctAnswer,
  quizId,
  gameDifficulty,
  nextQuiz,
  gameMode,
}: UseCheckAnswerArgs) => {
  const {
    handleCorrectAnswer,
    handleIncorrectAnswer,
    recordDailyChallengeAnswer,
    streakSaverCount,
    setStreakSaverCount,
    setUsedStreakSaver
  } = usePoints();

  const { user } = useAuth();
  const { showAlert } = useAlert();
  const checkAnswer = useCallback(
    (option: string) => {
      const isCorrect = option === correctAnswer;
      
      // Special handling for daily challenge
      if (gameMode === GameModeType.DailyChallenge && user?.uid) {
        nextQuiz();
        if (isCorrect) {
          showAlert("Correct!", "green");
          recordDailyChallengeAnswer(quizId, isCorrect, false);
        } else if (!isCorrect && streakSaverCount > 0) {
          showAlert("Wrong, but you had a streak saver!", "blue");
          // Decreasing streak saver count and updating usedStreakSaver flag
          setStreakSaverCount(streakSaverCount - 1);
          setUsedStreakSaver(true);
          recordDailyChallengeAnswer(quizId, true, false);
        } else {
          showAlert("Wrong, the answer was " + correctAnswer + ".", "red");
          recordDailyChallengeAnswer(quizId, false, false);
        }
      } else {
        // Normal mode behavior
        if (isCorrect) {
          handleCorrectAnswer(quizId, gameDifficulty);
          nextQuiz();
        } else {
          handleIncorrectAnswer(quizId, gameDifficulty);
        }
      }
    },
    [
      correctAnswer,
      handleCorrectAnswer,
      handleIncorrectAnswer,
      quizId,
      gameDifficulty,
      nextQuiz,
      gameMode,
      user?.uid,
      recordDailyChallengeAnswer,
      showAlert,
      streakSaverCount,
      setStreakSaverCount,
      setUsedStreakSaver
    ]
  );
  
  const handleDailyChallengeSkip = useCallback(() => {
    if (gameMode === GameModeType.DailyChallenge && user?.uid) {
      recordDailyChallengeAnswer(quizId, false, true);
      nextQuiz();
    }
  }, [gameMode, user?.uid, quizId, nextQuiz, recordDailyChallengeAnswer]);

  return { checkAnswer, handleDailyChallengeSkip };
};
