import { createCheckoutSession, createCustomerPortalSession } from '../api/stripe';

/**
 * Creates a subscription checkout session and redirects the user to Stripe
 * @param userId The ID of the user subscribing
 * @returns A Promise that resolves when the redirection is complete
 */
export const subscribeUserToPremium = async (userId: string): Promise<void> => {
  if (!userId) {
    throw new Error('User ID is required for subscription');
  }
  
  try {
    // Call the Firebase function to create a checkout session
    const session = await createCheckoutSession(userId);
    
    if (!session?.url) {
      console.error('Session is missing URL:', session);
      throw new Error('Failed to create checkout session');
    }
    
    // Redirect to Stripe Checkout URL
    console.log('Redirecting to Stripe checkout');
    window.location.href = session.url;
  } catch (error) {
    console.error('Error during subscription:', error);
    
    // Provide a more descriptive error message for debugging
    const errorMessage = error instanceof Error 
      ? `Subscription failed: ${error.message}`
      : 'Subscription failed for unknown reason';
      
    throw new Error(errorMessage);
  }
};

/**
 * Redirects the user to the Stripe customer portal to manage their subscription
 * @returns A Promise that resolves when the redirection is complete
 */
export const manageSubscription = async (): Promise<void> => {
  try {
    // Call the Firebase function to create a customer portal session
    const session = await createCustomerPortalSession();
    
    if (!session?.url) {
      console.error('Session is missing URL:', session);
      throw new Error('Failed to create customer portal session');
    }
    
    // Redirect to Stripe Customer Portal
    console.log('Redirecting to Stripe customer portal');
    window.location.href = session.url;
  } catch (error) {
    console.error('Error accessing subscription management:', error);
    
    // Provide a more descriptive error message for debugging
    const errorMessage = error instanceof Error 
      ? `Subscription management failed: ${error.message}`
      : 'Subscription management failed for unknown reason';
      
    throw new Error(errorMessage);
  }
}; 