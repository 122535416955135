import React from 'react'

import "./Instructions.css"

import SpecialButton from '../../../SpecialButton/SpecialButton'

interface InstructionsProps {
  handleStartGameFromInstructions: () => void;
}

const Instructions: React.FC<InstructionsProps> = ({ handleStartGameFromInstructions }) => {

  return (
    <div className='Instructions'>
      <h1><span>Nationality Guesser</span> is just a little game where you learn to distinguish nationalities. It's meant for entertainment and education, not offence.</h1>
      <SpecialButton text='I understand, Start' onClick={handleStartGameFromInstructions} />
    </div>
  )
}

export default Instructions