import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

import { GameStateType } from '../../types/GameStateType';
import { useGame } from '../../Context/GameContext';

const Footer: React.FC = () => {
  const { gameState } = useGame();

  if (gameState === GameStateType.Playing) {
    return null;
  }

  return (
    <div className='Footer'>
      <Link to="/privacypolicy">
        Privacy Policy
      </Link>
      <p>|</p>
      <Link to="/termsofservice">
        Terms of Service
      </Link>
    </div>
  );
};

export default Footer;
