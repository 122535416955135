interface DifficultyConfig {
  basePointsWin: number;
  basePointsLose: number;
  multiplier: number;
}

export const POINTS_CONFIG: Record<string, DifficultyConfig> = {
  EASY: {
    basePointsWin: 5,
    basePointsLose: 3,
    multiplier: 0.1,
  },
  MEDIUM: {
    basePointsWin: 7.5,
    basePointsLose: 3,
    multiplier: 0.15,
  },
  HARD: {
    basePointsWin: 10,
    basePointsLose: 3,
    multiplier: 0.2,
  },
};