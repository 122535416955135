import React from 'react'

import "./LeaderboardMember.css"

import { LeaderboardMemberType } from '../../../../types/LeaderboardMemberType'
import { DailyChallengeAnswer } from '../../../../types/DailyChallengeType'

import { useAuth } from '../../../../Context/AuthContext'

import PointsStreak from '../../Game/Statistics/PointsStreak/PointsStreak'
import DailyChallengeAnswers from './DailyChallengeAnswers/DailyChallengeAnswers'

import DiamondIcon from '@mui/icons-material/Diamond';

interface LeaderboardMemberProps {
  member: LeaderboardMemberType | {
    id: string;
    username: string;
    score: number;
    answers: DailyChallengeAnswer[];
    subscription?: "Standard" | "Premium";
  },
  rank: number,
  isDailyChallenge?: boolean;
}

const levels = [0, 50, 150, 300, 500, 1000, 1750, 2500, 3500, 5000];
const levelNames = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

const LeaderboardMember: React.FC<LeaderboardMemberProps> = ({ member, rank, isDailyChallenge = false }) => {
  const { user } = useAuth();

  const isPremium = member.subscription === "Premium";
  const memberIsHim = member.id === user?.uid

  let rankClass = 'default';

  if (rank === 1) {
    rankClass = 'first';
  } else if (rank === 2) {
    rankClass = 'second';
  } else if (rank === 3) {
    rankClass = 'third';
  }
  
  const getLevelName = (points: number) => {
    for (let i = levels.length - 1; i >= 0; i--) {
      if (points >= levels[i]) {
        return(levelNames[i]);
      }
    }
  }

  // For standard leaderboard display
  const renderStandardStats = () => {
    const standardMember = member as LeaderboardMemberType;
    const currentLevelName: string | undefined = getLevelName(standardMember.currentPoints);
    
    return (
      <div className="friend-streak-points-container">
        <PointsStreak highscoreStreak={standardMember.highscoreStreak} imageType={rankClass === "default" ? "streakNo" : "streakWhiteBorder" } />
        <PointsStreak highscoreStreak={standardMember.currentPoints} currentLevelName={currentLevelName} />
      </div>
    );
  };

  // For daily challenge leaderboard display
  const renderDailyChallengeStats = () => {
    const dailyChallengeMember = member as { score: number, answers: DailyChallengeAnswer[] };
    
    return (
      <DailyChallengeAnswers answers={dailyChallengeMember.answers} />
    );
  };

  return (
    <div className={`LeaderboardMember ${rankClass} ${memberIsHim ? "isUser" : ""}`}>
      <div className="rank-user-container">
        <span className='rank'>{rank}</span>
        <div className="member-container">
          {isPremium && <DiamondIcon sx={{ fontSize: 16 }} />}
          <p className='member'>{memberIsHim ? "You" : member.username}</p>
        </div>
      </div>

      {isDailyChallenge ? renderDailyChallengeStats() : renderStandardStats()}
    </div>
  )
}

export default LeaderboardMember